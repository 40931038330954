var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tradeType === _vm.ORDER_TRADE_TYPE.SALES ||
    _vm.tradeType === _vm.ORDER_TRADE_TYPE.PURCHASE_AND_SALES
    ? _c(
        "el-table",
        {
          ref: "salesProductTable",
          attrs: { data: _vm.orderForm.salesProducts, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "No.", width: "50px" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("h5", { staticClass: "h5-el-table-col-index" }, [
                        _vm._v(_vm._s(scope.$index + 1)),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              1010444001
            ),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "300px" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [_c("span", [_vm._v("* ")]), _vm._v("Product Name")]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: `salesProducts.${scope.$index}.sysProductId`,
                            rules: _vm.rules.reqRule,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled:
                                  _vm.$attrs.propsView ===
                                    _vm.ORDER_VIEW.VIEW_ORDER ||
                                  !(
                                    scope.row.status === "PENDING" ||
                                    !scope.row.status
                                  ),
                              },
                              on: {
                                "visible-change": function ($event) {
                                  return _vm.handleProductNameVisibleChange(
                                    "SELLER"
                                  )
                                },
                                change: function ($event) {
                                  return _vm.handleProductNameChange(
                                    $event,
                                    scope.$index,
                                    "SELLER"
                                  )
                                },
                              },
                              model: {
                                value: scope.row.sysProductId,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "sysProductId", $$v)
                                },
                                expression: "scope.row.sysProductId",
                              },
                            },
                            _vm._l(_vm.productList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1553239360
            ),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "200px" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [_c("span", [_vm._v("* ")]), _vm._v("Specs")]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: `salesProducts.${scope.$index}.sysProductSpecificationId`,
                            rules: _vm.rules.reqRule,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled:
                                  _vm.$attrs.propsView ===
                                    _vm.ORDER_VIEW.VIEW_ORDER ||
                                  !(
                                    scope.row.status === "PENDING" ||
                                    !scope.row.status
                                  ),
                              },
                              on: {
                                "visible-change": function ($event) {
                                  return _vm.handleProductSpecsVisibleChange(
                                    "SELLER"
                                  )
                                },
                                change: function ($event) {
                                  return _vm.handleProductSpecsChange(
                                    $event,
                                    scope.$index,
                                    "SELLER"
                                  )
                                },
                              },
                              model: {
                                value: scope.row.sysProductSpecificationId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "sysProductSpecificationId",
                                    $$v
                                  )
                                },
                                expression:
                                  "scope.row.sysProductSpecificationId",
                              },
                            },
                            _vm._l(
                              _vm.productSpecificationList,
                              function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1152514695
            ),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "150px" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [_c("span", [_vm._v("* ")]), _vm._v("Min Qty")]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: `salesProducts.${scope.$index}.minimumQuantity`,
                            rules: _vm.rules.minQtyRule,
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              controls: false,
                              precision: 3,
                              min: null,
                              disabled:
                                _vm.$attrs.propsView ===
                                  _vm.ORDER_VIEW.VIEW_ORDER ||
                                !(
                                  scope.row.status === "PENDING" ||
                                  !scope.row.status
                                ),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleMinQtyChange(
                                  scope.$index,
                                  "SELLER"
                                )
                              },
                            },
                            model: {
                              value: scope.row.minimumQuantity,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "minimumQuantity", $$v)
                              },
                              expression: "scope.row.minimumQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1428553400
            ),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "150px" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [_c("span", [_vm._v("* ")]), _vm._v("Max Qty")]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: `salesProducts.${scope.$index}.maximumQuantity`,
                            rules: _vm.rules.maxQtyRule,
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              controls: false,
                              precision: 3,
                              min: null,
                              disabled:
                                _vm.$attrs.propsView ===
                                  _vm.ORDER_VIEW.VIEW_ORDER ||
                                !(
                                  scope.row.status === "PENDING" ||
                                  !scope.row.status
                                ),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleMaxQtyChange(
                                  scope.$index,
                                  "SELLER"
                                )
                              },
                            },
                            model: {
                              value: scope.row.maximumQuantity,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "maximumQuantity", $$v)
                              },
                              expression: "scope.row.maximumQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3302042406
            ),
          }),
          _c("el-table-column", {
            attrs: { label: "Final Qty", "min-width": "150px" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: `salesProducts.${scope.$index}.finalQuantity`,
                            rules: _vm.rules.finalQtyRule,
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              controls: false,
                              precision: 3,
                              min: null,
                              disabled:
                                _vm.$attrs.propsView ===
                                  _vm.ORDER_VIEW.VIEW_ORDER ||
                                !(
                                  scope.row.status === "PENDING" ||
                                  !scope.row.status
                                ),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleFinalQtyChange(
                                  scope.$index,
                                  "SELLER"
                                )
                              },
                            },
                            model: {
                              value: scope.row.finalQuantity,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "finalQuantity", $$v)
                              },
                              expression: "scope.row.finalQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              4143793904
            ),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "120px" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [_c("span", [_vm._v("* ")]), _vm._v("Unit Metric")]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: `salesProducts.${scope.$index}.unitMetric`,
                            rules: _vm.rules.reqRule,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: scope.row.unitMetric,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "unitMetric", $$v)
                                },
                                expression: "scope.row.unitMetric",
                              },
                            },
                            _vm._l(_vm.UNIT_OPTIONS, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1741037488
            ),
          }),
          !(
            _vm.linkedSalesTermContract &&
            _vm.linkedSalesTermContract.termContractType === "TIME_CHARTER"
          ) && !_vm.isOps
            ? _c("el-table-column", {
                attrs: { "min-width": "150px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [_c("span", [_vm._v("* ")]), _vm._v("Price")]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: `salesProducts.${scope.$index}.price`,
                                rules: _vm.rules.reqRule,
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  controls: false,
                                  precision: 3,
                                  min: null,
                                  disabled:
                                    _vm.$attrs.propsView ===
                                      _vm.ORDER_VIEW.VIEW_ORDER ||
                                    !(
                                      scope.row.status === "PENDING" ||
                                      !scope.row.status
                                    ),
                                },
                                model: {
                                  value: scope.row.price,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "price", $$v)
                                  },
                                  expression: "scope.row.price",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2654659121
                ),
              })
            : _vm._e(),
          !_vm.isOps
            ? _c("el-table-column", {
                attrs: { label: "Currency", "min-width": "120px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: scope.row.currency,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "currency", $$v)
                                    },
                                    expression: "scope.row.currency",
                                  },
                                },
                                _vm._l(_vm.currencyList, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.code,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2359304257
                ),
              })
            : _vm._e(),
          _vm.orderForm.orderType !== _vm.ORDER_TYPE.EX_WHARF
            ? _c("el-table-column", {
                attrs: { "min-width": "250px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("span", [_vm._v("* ")]),
                          _vm._v("Physical Supplier BDN"),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: `salesProducts.${scope.$index}.physicalSupplierSysOrganizationId`,
                                rules: _vm.rules.reqRule,
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    "value-key": "id",
                                    disabled:
                                      _vm.$attrs.propsView ===
                                        _vm.ORDER_VIEW.VIEW_ORDER ||
                                      !(
                                        scope.row.status === "PENDING" ||
                                        !scope.row.status
                                      ) ||
                                      _vm.productsVerifiedList
                                        .filter(
                                          (p) =>
                                            p.sysProductId ===
                                            scope.row.sysProductId
                                        )
                                        .filter(
                                          (p) =>
                                            p.sysProductSpecificationId ===
                                            scope.row.sysProductSpecificationId
                                        ).length > 0,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handlePhysicalSupplierBdnChange(
                                        $event,
                                        scope.$index,
                                        "SELLER"
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      scope.row
                                        .physicalSupplierSysOrganizationId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "physicalSupplierSysOrganizationId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "scope.row.physicalSupplierSysOrganizationId",
                                  },
                                },
                                _vm._l(
                                  _vm.physicalSupplierList,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.companyName,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3617859307
                ),
              })
            : _vm._e(),
          _vm.orderForm.orderType !== _vm.ORDER_TYPE.BARGING_NOMINATION
            ? _c("el-table-column", {
                attrs: { label: "Price Type", "min-width": "120px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.$attrs.propsView ===
                                        _vm.ORDER_VIEW.VIEW_ORDER ||
                                      !(
                                        scope.row.status === "PENDING" ||
                                        !scope.row.status
                                      ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handlePriceTypeSelect(
                                        $event,
                                        scope.row,
                                        "SELLER",
                                        scope.$index
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.priceType,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "priceType", $$v)
                                    },
                                    expression: "scope.row.priceType",
                                  },
                                },
                                _vm._l(
                                  _vm.ORDER_PRICE_TYPE_OPTIONS,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1204910201
                ),
              })
            : _vm._e(),
          !_vm.isOps &&
          _vm.orderForm.orderType !== _vm.ORDER_TYPE.BARGING_NOMINATION
            ? _c("el-table-column", {
                attrs: {
                  width:
                    _vm.orderForm.orderType === _vm.ORDER_TYPE.EX_WHARF &&
                    _vm.$attrs.propsView !== _vm.ORDER_VIEW.CREATE_ORDER
                      ? "240px"
                      : "auto",
                },
              })
            : _vm._e(),
          _vm.orderForm.orderType === _vm.ORDER_TYPE.DELIVERED ||
          (_vm.orderForm.orderType !== _vm.ORDER_TYPE.DELIVERED &&
            _vm.orderForm.salesProducts[0].status &&
            _vm.orderForm.salesProducts[0].status !== "PENDING")
            ? _c("el-table-column", {
                attrs: { label: "", width: "50px", fixed: "right" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            [
                              scope.row.status === "PENDING" ||
                              !scope.row.status
                                ? _c("el-button", {
                                    attrs: {
                                      type: "danger",
                                      icon: "el-icon-delete",
                                      size: "mini",
                                      circle: "",
                                      disabled:
                                        _vm.$attrs.propsView ===
                                          _vm.ORDER_VIEW.VIEW_ORDER ||
                                        _vm.orderForm.salesProducts.length ===
                                          1,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteAlert(
                                          true,
                                          scope.row,
                                          scope.$index,
                                          "SELLER"
                                        )
                                      },
                                    },
                                  })
                                : _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "Verified",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticClass: "el-icon-check",
                                        attrs: {
                                          type: "success",
                                          size: "mini",
                                          circle: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3912437131
                ),
              })
            : _vm._e(),
          !_vm.isOps &&
          _vm.orderForm.orderType !== _vm.ORDER_TYPE.BARGING_NOMINATION
            ? _c("el-table-column", {
                attrs: {
                  type: "expand",
                  "min-width": "50px",
                  width: "auto",
                  fixed: "right",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("table-col-product-pricing", {
                            attrs: {
                              parent: "OrderFormSalesProduct",
                              nominationDate: new Date(
                                _vm.orderForm.nominationDate
                              ),
                              scopeRow: scope.row,
                              scopeIndex: scope.$index,
                              priceProp: "salesProducts",
                              priceType: scope.row.priceType,
                              priceMethodologyList: _vm.priceMethodologyList,
                              rulesReq: _vm.rules.reqRule,
                              notReqRule: _vm.rules.notReqRule,
                              holidayMap: _vm.holidayMap,
                              resetProductPriceWatcher:
                                _vm.resetProductPriceWatcher,
                              isAllFieldsDisabled:
                                _vm.$attrs.propsView ===
                                  _vm.ORDER_VIEW.VIEW_ORDER ||
                                !(
                                  scope.row.status === "PENDING" ||
                                  !scope.row.status
                                ),
                            },
                            on: {
                              handlePriceInfoChange: _vm.handlePriceInfoChange,
                              handleReferencePriceChange:
                                _vm.handleReferencePriceChange,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  147660702
                ),
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }