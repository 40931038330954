<template>
	<el-table ref="salesProductTable"
    v-if="tradeType === ORDER_TRADE_TYPE.SALES || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES"
    :data="orderForm.salesProducts" border >
    <el-table-column label="No." width="50px">
      <template v-slot="scope">
        <h5 class="h5-el-table-col-index">{{scope.$index + 1}}</h5>
      </template>
    </el-table-column>
    <el-table-column min-width="300px">
      <template v-slot:header><span>* </span>Product Name</template>
      <template v-slot="scope">
        <el-form-item :prop="`salesProducts.${scope.$index}.sysProductId`" :rules="rules.reqRule">
          <el-select v-model="scope.row.sysProductId"
            :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
            @visible-change="handleProductNameVisibleChange('SELLER')"
            @change="handleProductNameChange($event, scope.$index, 'SELLER')">
            <el-option
              v-for="item in productList"
              :key="item.id" :label="item.name" :value="item.id"
            />
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column min-width="200px">
      <template v-slot:header><span>* </span>Specs</template>
      <template v-slot="scope">
        <el-form-item :prop="`salesProducts.${scope.$index}.sysProductSpecificationId`" :rules="rules.reqRule">
          <el-select
            v-model="scope.row.sysProductSpecificationId"
            :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
            @visible-change="handleProductSpecsVisibleChange('SELLER')"
            @change="handleProductSpecsChange($event, scope.$index, 'SELLER')"
          >
            <el-option
              v-for="item in productSpecificationList"
              :key="item.id" :label="item.name" :value="item.id"
            />
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column min-width="150px">
      <template v-slot:header><span>* </span>Min Qty</template>
      <template v-slot="scope">
        <el-form-item :prop="`salesProducts.${scope.$index}.minimumQuantity`" :rules="rules.minQtyRule">
          <el-input-number
            v-model="scope.row.minimumQuantity"
            :controls="false" :precision="3" :min="null"
            @change="handleMinQtyChange(scope.$index, 'SELLER')"
            :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
          />
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column min-width="150px">
      <template v-slot:header><span>* </span>Max Qty</template>
      <template v-slot="scope">
        <el-form-item :prop="`salesProducts.${scope.$index}.maximumQuantity`" :rules="rules.maxQtyRule">
          <el-input-number
            v-model="scope.row.maximumQuantity"
            :controls="false" :precision="3" :min="null"
            @change="handleMaxQtyChange(scope.$index, 'SELLER')"
            :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
          />
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="Final Qty" min-width="150px">
      <template v-slot="scope">
        <el-form-item :prop="`salesProducts.${scope.$index}.finalQuantity`" :rules="rules.finalQtyRule">
          <el-input-number
            v-model="scope.row.finalQuantity"
            :controls="false" :precision="3" :min="null"
            @change="handleFinalQtyChange(scope.$index, 'SELLER')"
            :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
          />
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column min-width="120px">
      <template v-slot:header><span>* </span>Unit Metric</template>
      <template v-slot="scope">
        <el-form-item :prop="`salesProducts.${scope.$index}.unitMetric`" :rules="rules.reqRule">
          <el-select v-model="scope.row.unitMetric" disabled>
            <el-option
              v-for="item in UNIT_OPTIONS"
              :key="item.value" :label="item.label" :value="item.value"
            />
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column v-if="!(linkedSalesTermContract && linkedSalesTermContract.termContractType === 'TIME_CHARTER') && !isOps" min-width="150px">
      <template v-slot:header><span>* </span>Price</template>
      <template v-slot="scope">
        <el-form-item :prop="`salesProducts.${scope.$index}.price`" :rules="rules.reqRule">
          <el-input-number
            v-model="scope.row.price"
            :controls="false" :precision="3" :min="null"
            :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
          />
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="Currency" min-width="120px" v-if="!isOps">
      <template v-slot="scope">
        <el-form-item>
          <el-select v-model="scope.row.currency" disabled>
            <el-option
              v-for="item in currencyList"
              :key="item.id" :label="item.code" :value="item.code"
            />
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column min-width="250px" v-if="orderForm.orderType !== ORDER_TYPE.EX_WHARF">
      <template v-slot:header><span>* </span>Physical Supplier BDN</template>
      <template v-slot="scope">
        <el-form-item :prop="`salesProducts.${scope.$index}.physicalSupplierSysOrganizationId`" :rules="rules.reqRule">
          <el-select
            v-model="scope.row.physicalSupplierSysOrganizationId" filterable value-key="id"
            :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status) ||
              productsVerifiedList.filter(p => p.sysProductId === scope.row.sysProductId).filter(p => p.sysProductSpecificationId === scope.row.sysProductSpecificationId).length > 0"
            @change="handlePhysicalSupplierBdnChange($event, scope.$index, 'SELLER')"
          >
            <el-option
              v-for="item in physicalSupplierList"
              :key="item.id" :label="item.companyName" :value="item.id"
            />
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column v-if="orderForm.orderType !== ORDER_TYPE.BARGING_NOMINATION" label="Price Type" min-width="120px">
      <template v-slot="scope">
        <el-form-item>
          <el-select
            v-model="scope.row.priceType"
            :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
            @change="handlePriceTypeSelect($event, scope.row, 'SELLER', scope.$index)"
          >
            <el-option
              v-for="item in ORDER_PRICE_TYPE_OPTIONS"
              :key="item.value" :label="item.label" :value="item.value"
            />
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column v-if="!isOps && orderForm.orderType !== ORDER_TYPE.BARGING_NOMINATION" :width="orderForm.orderType === ORDER_TYPE.EX_WHARF && $attrs.propsView !== ORDER_VIEW.CREATE_ORDER ? '240px' : 'auto'"/>
    <el-table-column v-if="orderForm.orderType === ORDER_TYPE.DELIVERED || (orderForm.orderType !== ORDER_TYPE.DELIVERED && orderForm.salesProducts[0].status && orderForm.salesProducts[0].status !== 'PENDING')" label="" width="50px" fixed="right" >
      <template v-slot="scope">
        <div>
          <el-button v-if="scope.row.status === 'PENDING' || !scope.row.status" type="danger" icon="el-icon-delete" size="mini" circle @click="deleteAlert(true,scope.row,scope.$index,'SELLER')" :disabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER || orderForm.salesProducts.length === 1"/>
          <el-tooltip v-else class="item" effect="dark" content="Verified" placement="top">
            <el-button class="el-icon-check" type="success" size="mini" circle/>
          </el-tooltip>
        </div>
        </template>
    </el-table-column>
    <el-table-column v-if="!isOps && orderForm.orderType !== ORDER_TYPE.BARGING_NOMINATION" type="expand" min-width="50px" width="auto" fixed="right">
      <template v-slot="scope">
        <table-col-product-pricing parent='OrderFormSalesProduct' :nominationDate="new Date(orderForm.nominationDate)" :scopeRow="scope.row" :scopeIndex="scope.$index" priceProp="salesProducts" :priceType="scope.row.priceType" :priceMethodologyList="priceMethodologyList"
          :rulesReq="rules.reqRule" :notReqRule="rules.notReqRule" @handlePriceInfoChange="handlePriceInfoChange" @handleReferencePriceChange="handleReferencePriceChange"
          :holidayMap="holidayMap" :resetProductPriceWatcher="resetProductPriceWatcher" :isAllFieldsDisabled="$attrs.propsView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
        />
      </template>
    </el-table-column>
  </el-table>
</template>
<script>

import { mapState } from 'vuex'
import { ORDER_VIEW, UNIT_OPTIONS, ORDER_PRICE_TYPE_OPTIONS, ORDER_TYPE, ORDER_TRADE_TYPE } from '@/constants.js'
import TableColProductPricing from './TableColProductPricing'
// import SalesProductTable from './SalesProductTable.vue'
//  SalesProductTable
/**
  <sales-product-table
    v-bind="$props"
    :orderForm="orderForm"
    :rules="rules"
    :productsVerifiedList="productsVerifiedList"
    :physicalSupplierList="physicalSupplierList"
    :priceMethodologyList="priceMethodologyList"
    :holidayMap="holidayMap"
    :resetProductPriceWatcher="resetProductPriceWatcher"
    :tradeType="tradeType"
    :linkedSalesTermContract="linkedSalesTermContract"
    :handleProductNameVisibleChange="handleProductNameVisibleChange"
    :handleProductNameChange="handleProductNameChange"
    :handleProductSpecsVisibleChange="handleProductSpecsVisibleChange"
    :handleProductSpecsChange="handleProductSpecsChange"
    :handleMinQtyChange="handleMinQtyChange"
    :handleMaxQtyChange="handleMaxQtyChange"
    :handleFinalQtyChange="handleFinalQtyChange"
    :handlePhysicalSupplierBdnChange="handlePhysicalSupplierBdnChange"
    :handlePriceTypeSelect="handlePriceTypeSelect"
    :deleteAlert="deleteAlert"
    :handlePriceInfoChange="handlePriceInfoChange"
    :handleReferencePriceChange="handleReferencePriceChange"
  />
 * */

export default {
  name: 'SalesProductTable',
  components: {
    TableColProductPricing,
  },
  props: {
    rules: Object,
    orderForm: Object,
    productsVerifiedList: Array,
    physicalSupplierList: Array,
    priceMethodologyList: Array,
    resetProductPriceWatcher:Boolean,
    holidayMap: Map,
    tradeType: String,
    linkedSalesTermContract:Object,

  },
  data () {

    return {
      ORDER_VIEW,
      ORDER_TRADE_TYPE,
      UNIT_OPTIONS,
      ORDER_TYPE,
      ORDER_PRICE_TYPE_OPTIONS

    }
  },
  computed: {
    ...mapState([
      'currencyList',
      'productList',
      'productSpecificationList',
    ]),
  },
  watch: {

  },
  created () {

  },
  methods: {
    onChangeCompanyName($event){
      this.$emit('handleCompanyNameSelect',$event, 'BUYER')
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../index";
</style>
